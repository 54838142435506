import React from "react"
import _ from "lodash"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Content from "../components/content"
import Sidebar from "../components/sidebar"

const HCP = (props) => (
  <Layout page="hcp" sections={props.pageContext.sections} lang={props.pageContext.loc} dir={props.pageContext.loc === "he-il"? "rtl":"ltr"}>
    <SEO title="Kartos Boreas" lang={props.pageContext.loc} dir={props.pageContext.loc === "he-il"? "rtl":"ltr"}/>
    <Content sections={props.pageContext.sections} lang={props.pageContext.loc} page="hcp" dir={props.pageContext.loc === "he-il"? "rtl":"ltr"}/>
    <Sidebar page="hcp" lang={props.pageContext.loc} content={_.find(props.pageContext.sections, {"section_title": "sidebar"})}/>
  </Layout>
)

export default HCP
